@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

.add-button {
  position: absolute;
  top: 1px;
  left: 1px;
}

body {
  background-color: #eaf1fd;
  margin: 0;

  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

© body::-webkit-scrollbar {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.MuiBackdrop-root {
  background-color: transparent !important;
}

.MuiList-padding {
  padding: 20px !important;
}

.ListPrivacyPolicy {
  margin-left: -25px !important;
}

.ListPrivacyPolicy li {
  margin-bottom: 5px;
}

.captcha-padding {
  padding-top: 20px;
  padding-bottom: 20px;
}

.scroll-container {
  overflow: hidden;
}

.scroll-text {
  /* animation properties */
  -moz-transform: translateY(100%);
  -webkit-transform: translateY(100%);
  transform: translateY(100%);

  -moz-animation: my-animation 4 s linear infinite;
  -webkit-animation: my-animation 4s linear infinite;
  animation: my-animation 4s linear infinite;
}

/* for Firefox */
@-moz-keyframes my-animation {
  from {
    -moz-transform: translateY(100%);
  }

  to {
    -moz-transform: translateY(-100%);
  }
}

/* for Chrome */
@-webkit-keyframes my-animation {
  from {
    -webkit-transform: translateY(100%);
  }

  to {
    -webkit-transform: translateY(-100%);
  }
}

@keyframes my-animation {
  from {
    -moz-transform: translateY(100%);
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }

  to {
    -moz-transform: translateY(-100%);
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
}

* {
  box-sizing: border-box;
}

/********** chakra page **************/
ul.auraTypeName {
  position: absolute;
  z-index: 999;
  left: 0;
  top: 0;
  padding: 10px 5px;
  list-style-type: none;
  height: 100%;
  background: #0006;
  margin: 0;
  font-size: 12px;
  width: 170px;
  color: #ffffff;
}

ul.auraTypeName li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1px;
  padding: 5px;
}

ul.auraTypeName li.acitveChakra {
  background: linear-gradient(90deg, #f10f10, #1b6cba);
}

.bg_screen.bg_chakra {
  background-image: url(../src/assets/chakra-bg2.png);
}

/********** chakra page ends**************/
.videoContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

label.MuiInputLabel-shrink {
  background: #ffffff !important;
  padding: 5px !important;
  top: -5px !important;
  border-radius: 5px !important;
  color: #000 !important;
}

.MuiFormControl-root label:not(.MuiInputLabel-shrink) {
  color: #ffffff !important;
}

/******** user records *****/
.userRecords-wrapper {
  width: 400px;
  max-width: 100%;
  margin: 30px auto;
  padding: 0 15px;
  background: #00000074;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 75vh;
}

.userRecords-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0;
}

.userRecords-header .MuiButton-text {
  border-radius: 8px;
  border: 4px solid #07263e;
  position: relative;
  background: #133e5f !important;
  color: #fff;
  padding: 5px 15px;
  box-sizing: border-box;
}

.userRecords-header .MuiButton-text::before {
  content: '';
  position: absolute;
  left: 0;
  top: -4px;
  right: 0;
  margin: auto;
  height: 4px;
  width: 100%;
  background: linear-gradient(to right,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 50%,
      rgba(255, 255, 255, 0) 100%);
}

.userRecords-header .MuiButton-text::after {
  content: '';
  position: absolute;
  top: auto;
  bottom: -4px;
  right: 0;
  width: 100%;
  margin: auto;
  height: 4px;
  background: linear-gradient(to right,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 50%,
      rgba(255, 255, 255, 0) 100%);
}

.userRecords-bodyList {
  padding: 0 15px 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid #ffffff;
  display: flex;
  gap: 10px;
  align-items: center;
}

.userRecords-bodyList img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
}

.userRecords-bodyList p {
  width: calc(100% - 40px);
  margin: 0;
}

.userRecords-bodyList:last-child {
  border-bottom: 0;
}

.userRecords-body p {
  color: #ffffff;
}

.analyzeButton button {
  position: relative;
}

.analyzeButton button .MuiCircularProgress-root {
  position: absolute;
  background: #fff;
  border-radius: 50%;
}

.analyzeButton button .MuiCircularProgress-root svg {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.analyzeButton button .MuiButton-label {
  display: flex;
  align-items: center;
  justify-content: center;
}

.lifeContainer {
  justify-content: center;
}

.harmonizing_bg {
  background-image: url(../src/assets/scanRound.png);
  background-size: cover;
  background-position: center;
  height: calc(100vh - 200px);
  position: relative;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 360px;
}

.harmonizing_bg::before {
  position: absolute;
  height: 324px;
  width: 318px;
  /* height: 170px;
  width: 170px; */
  top: 52%;
  left: 50%;
  content: "";
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 1;
  background-image: url(../src/assets/auraScan1.png);
  background-size: cover;
  background-repeat: no-repeat;
}

.mobileHeaderLogo {
  width: 150px;
  margin: 0 auto;
  display: block;
  height: auto;
}